<template>
  <div class="address-page">
    <headerBar :title="i18n.t('address.title')"  :showArrow="true" @back="back"></headerBar>

   <div class="address-list">
      <div class="list flex-center" v-for="(item, i) in addressList" :key="i">
        <div class="header">Dr</div>
        <div class="flex-1 info">
          <div class="line">
            <span class="name">{{item.username}}</span>
            <span>{{item.phone}}</span>
          </div>
          <div>
            {{item.Province}} {{item.City}}
          </div>
          <div>
            {{item.Address}}
          </div>
        </div>
      </div>

      <div class="add-line flex-center" @click="toAdd">
        <img :src="addUrl" alt="">
        <span>添加收货地址</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      addUrl: require('../../assets/imgs/icon-add.png'),
      addressList: [],
    }
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toAdd() {
      this.$router.push({
        name: 'addAddress'
      })
    }
  }
}
</script>